<template>
  <div id="main" class="main__ukraine main__region brends__ukraine" :class="{ loaded: loaded}">
    <div class="map-zone__controls">
      <div class="container">
        <a href="#" @click.prevent="isFilter=!isFilter">Фільтри/Стелла</a>
      </div>        
    </div>
    <div class="map-zone container"
      :class="{'show-filter': isFilter, 'show-mapinfo': isMapInfo}" 
    >      
      <div v-show="!loaded || !idBrend" class="loading">loading......</div>

      <div class="map-ukr__inner map__inner"
          :class="{'waiting' : !loaded || !idBrend}"
          @click="isFilter=false;" 
      >
      <div class="regionselector dropdown-area">
        <div class="dropdown-area"
          :class="{ active: regionSelector, 'mobile-view': isMob }"
        >
          <div class="dropdown-value"
            @click="dropRegions"
            @mouseover="clearTimeoutDropRegion"
            @mouseleave="countdownDropRegions()"        
          > 
            <i>{{getRayName}}</i>
            <span class="arrow"></span>
          </div>

          <transition name="dropdown">
            <div class="as-dropdown"
              v-if="regionSelector"                            
              @mouseover="clearTimeoutDropRegion"
              @mouseleave="countdownDropRegions(500)"
            >
              
              <ul class="as-dropdown__list scroll-bar">
                <li 
                  v-for="(brend, index) in brendList"
                  :key="index"
                >
                  <a :href="'/brend/' + brend.name" 
                      @click.prevent="selectRegion(brend.path)"
                  >
                    {{brend.name}}
                  </a>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
        <mapUkr
          :regionsData="regionsData"
          :targetLink="'/regions/'"/>
      </div>

      <div class="map-zone__toolbar">
        <stella
          v-if="idBrend"
          :idBrend="+idBrend"
          :fuelsList="fuelsList"
          @changed="updateMapData"
          @selectBrend="showModal"
        />
      </div>

    </div><!-- map-zone -->
    
    <div class="modal" 
      @click.self="hideModal"
      :class="{'active': modal.active, 'before-active': modal.before }"  
    >
      <div class="modal-container brend-selection">
        <h2 class="modal-title"><span>Оберіть бренд</span></h2>

        <a href="#" @click.prevent="hideModal" class="btn-close">Сховати</a>    

        <ul class="flx-wrap flx-jc__sb scroll-bar">
          <li class="flx__c" 
            v-for="(brend, index) in brendList" 
            :key="index"
            :class="'brand__' + brend.id + (brend.id==idBrend ? ' active' : '') "
            @click="hideModal"
          >
            <img :src="'http://azs.uapetrol.com/images/brends/brend' + brend.id + '.gif'" />
            <router-link
                class="link--absolute"
                :to='"/brends/" + brend.path'>
                  
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <billboard /> 

    <div class="grid-zone container">
      <core-grid
        v-if="idBrend"
        :dataList="table.gridData"
        :columns="table.gridColumns"
        :filter-key="table.searchQuery"
        :url="'/regions/'"
        :urlData="'idReg'"
        :columnsCount="table.columnsCount"
        :routerLink="true"
      />
      <core-sidebar />
    </div>

  </div>
</template>


<style lang="scss">
  @import './src/assets/scss/sections/brends.scss';
</style>


<script>
  import mapUkr from './../../components/body/map-ukr.vue';
  import stella from './../../components/body/stella.vue';
  import billboard from './../../components/body/billboard.vue';

  import CoreSidebar from './../../components/core/Sidebar.vue';
  import CoreGrid from './../../components/core/Grid.vue';

  import axios from 'axios';

  const convert = require('xml-js');

  let dropRegionsTimeout;

  export default {
    name: 'Brands',
    metaInfo() {
      return {
        titleTemplate: this.metaProps.title,
        meta: [
          { name: 'description', content: this.metaProps.desc},
          { property: 'og:title', content: this.metaProps.title},
          // { property: 'og:site_name', content: 'АЗС України'},
          // {property: 'og:type', content: 'website'},    
          // {name: 'robots', content: 'index,follow'} 
        ]
      }
    },
    data: () => ({
      loaded: false,
      showBrendSelector: true,
      defaultBrend: 6,
      idBrend: 0,
      brendList: [],
      fuelsList: [],
      table: {
        searchQuery: '',
        gridColumns: [
          { 
            label: '',
            name: 'name'
          }
        ],
        gridData: [],
        columnsCount: 0
      },
      getDataXml: '/assets/data/getData.xml',
      getGridDataURL: '/assets/data/getGridDataJSON.pl',
      getFuelsListUrl: '/assets/data/getFuelsList.pl',
      isFilter: false,
      isMapInfo: false,
      regionsData: {},
      errors: [],
      modal: {
        before: false,
        active: false
      },
      regionSelector: false
    }),
    components: {
      mapUkr,
      stella,
      billboard,
      CoreGrid,
      CoreSidebar
    },
    watch: {
      idBrend: function() {
        if (!this.isBrendHasStore()) {
          this.updateBrendPrices();
        }
        this.updateMapData();
      },
      $route () {
        const brendName = this.$route.params.brendName;
        this.idBrend = brendName && this.brends[brendName] ? this.brends[brendName].id : this.defaultBrend;
        if (brendName && !this.brends[brendName]) {
          this.showModal();
        }
      }
    },
    created() {
      axios
        .get('/assets/data/main.json')
        .then(response => {
          this.getDataXml = response.data.getDataUkr;
          this.getGridDataBrendJSON = response.data.getGridDataBrendJSON;
          this.getFuelsListUrl = response.data.getFuelsList;

          //brendList & idBrend
          const brends = response.data.brends,
                brendList = [];
          this.brends = brends;

          for (let key in brends) {
            let brend = brends[key];
            brend.path = key;
            brendList.push(brend);
          }

          this.brendList = brendList;

          const brendName = this.$route.params.brendName;
          this.idBrend = brendName && this.brends[brendName] && this.brends[brendName].id ? this.brends[brendName].id : this.defaultBrend;

          if ( (brendName && !this.brends[brendName]) || !brendName ) {
            this.showModal();
          }
          this.updateAllData();

        })
        .catch(error => {
          console.log(error);
          this.errors.push(error);
        });
    },
    computed: {
      isMob() {
        return window.isMob;
      },
      getRayName() {
        let name = 'Оберіть бренд...';
        const brand = this.$route.params.brendName;

        if (!brand) {
          return name;
        }

        for(var i = 0; i < this.brendList.length; i++) {
          if (this.brendList[i].id == this.idBrend) {
            name = this.brendList[i].name;
            break;
          }
        }
        return name;
      },
      metaProps() {
        let title = '%s - Бренди',
            desc = 'АЗС України - Ціни на бензин, дизпаливо, газ в мережах OKKO, WOG, Shell, Socar, ANP та інші';

        
        const brand = this.idBrend && this.$route.params.brendName ? this.brendList.find(obj => obj.id == this.idBrend) : null;

        if (brand && brand.name) {
          title = 'Ціни на АЗС ' + brand.name + ' - %s';
          desc = 'Актуальні ціни на пальне в мережі АЗС ' + brand.name; 
        }

        return {title: title, desc: desc};
      }
    },
    methods: {
      dropRegions() {
        this.regionSelector = !this.regionSelector;
      },
      selectRegion(id) {
        this.countdownDropRegions(0);
        this.$router.push('/brends/' + id);
      },
      clearTimeoutDropRegion() {
        clearTimeout(dropRegionsTimeout);
      },
      countdownDropRegions(timer) {
        timer = timer || timer === 0 ? timer : 1500;
        clearTimeout(dropRegionsTimeout);

        dropRegionsTimeout = setTimeout(() => {
          this.regionSelector = false;
        }, timer);

      },
      isBrendHasStore() {
        const brendData = this.$store.getters.getBrendData[this.idBrend];
        let hasStore = false;

        if (brendData) {
          this.table.gridColumns = brendData.table.gridColumns;
          this.table.gridData = brendData.table.gridData;
          this.table.columnsCount = brendData.table.columnsCount;

          this.fuelsList = brendData.fuelList

          hasStore = true;
        }

        return hasStore;
      },
      showModal() {
        this.modal.before = true;
        setTimeout(() => {
          this.modal.active = true;
        }, 300);
      },
      hideModal() {
        this.modal.active = false;
        setTimeout(() => {
          this.modal.before = false;
        }, 300);
      },
      setBrend(id) {
        this.idBrend = id;
        this.hideModal();
      },
      updateAllData(){
        this.updateMapData();

        if (!this.isBrendHasStore()) {
          this.updateBrendPrices();
        }
      },
      updateMapData() {  
        this.loaded = false;
        
        axios
          //.get('http://azs.uapetrol.com/cgi-bin/getData.pl' + paramsStr)
          .get(this.getDataXml + this.getUrlParams())
          .then(response => {
            var jsonStr = convert.xml2json(response.data, {compact: true});
            var json = JSON.parse(jsonStr);
            var regArr = json.map.item;

            const regInfo = json.map._attributes;

            var ukraine = {
              regInfo: {
                chDate: regInfo.chDate,
                stepPrice: regInfo.stepPrice,
                price1: regInfo.price1,
                price2: regInfo.price2,
                price3: regInfo.price3,
                price4: regInfo.price4
              }
            };
            

            for(let i = 0; i < regArr.length; i++){
              ukraine[regArr[i]._attributes.id] = regArr[i]._attributes;
            }
          
            this.regionsData = ukraine;

          })
          .catch(error => {
            this.errors.push(error);
          })
          .finally(
            () => {
              this.loaded = true;
            }      
          );
      },
      updateBrendPrices() {
      //Table
        const brendData = {};

        const urlParams = this.getUrlParams();

        axios
          .get(this.getGridDataBrendJSON + `?brend=${this.idBrend}&timestamp=brendspage`)
          .then(response => {
            
            const gridColumns = [
              {
                label: 'Регіон',
                name: 'reg'            
              }
            ];

            response.data.struct.forEach((element, index) => {
              if (index) {
                gridColumns.push(
                  {
                    label: element.name,
                    name: element.field            
                  }
                );
              }              
            });

            this.table.gridColumns = gridColumns;
            this.table.gridData = response.data.data.items;
            this.table.columnsCount = response.data.struct.length;

            brendData.table = {
                gridColumns: gridColumns,
                gridData: response.data.data.items,
                columnsCount: response.data.struct.length
            };
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          });

        //Stella
        axios
          .get(this.getFuelsListUrl + urlParams)
          .then(response => {
            const fuelList = response.data.data
            this.fuelsList = fuelList;

            const fuelSelected = [];

            fuelList.forEach((fuel) => {
              fuelSelected.push(fuel.id);
            });

            brendData.fuelList = fuelList;
            brendData.fuelSelected = fuelSelected;

            this.$store.dispatch('addBrend', {idBrend: this.idBrend, data: brendData})
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      },
      getUrlParams() {
        const brendData = this.$store.getters.getBrendData[this.idBrend];
        let paramsStr, params;
        params = brendData ? brendData.fuelSelected : ''
        paramsStr = `?brend=${this.idBrend}&timestamp=brendspage`;
        paramsStr += params && params.length ? '&postData=' + params.join("|") : '';

        return paramsStr;
      }
    }
  }
</script>